@keyframes spinn {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

#form-modal {
  justify-content: flex-end;
  align-items: flex-end;
  border-left: 1px dashed #333 !important;
}

@media (max-width: 767px) {
  #form-modal {
    width: 100% !important;
  }
}

.pagination {
  display: flex;
  list-style: none;
  height: 100px;
  gap: 5px;
}

.pagination-alternative {
  display: flex;
  list-style: none;
  justify-content: flex-end;
  height: auto;
  gap: 5px;
  height: 30px;
}

.pagination a {
  padding: 5px 12px;
  border-radius: 6px;
  border: 1px solid transparent;
  background-color: #edf1fe;
  color: #2f52bb !important;
  white-space: nowrap;
}

.pagination-active a {
  background-color: #435085;
  color: #fff !important;
}

.pagination-disabled {
  opacity: 50%;
}

#ul-list-actions {
  display: flex;
  flex-direction: row;
}

#ul-list-actions > button {
  background-color: transparent;
  width: 20px;
  border: none;
  outline: none;
  cursor: default !important;
}

#ul-list-actions i,
button span {
  cursor: pointer;
}

#ul-list-actions i:hover,
button span:hover {
  color: #435085 !important;
}

#trash-icon i:hover {
  color: orangered !important;
}

.transition-end {
  transform: translateX(100%) !important;
}

.spinner-loading-form {
  animation: spinn 1s infinite ease;
}

.tr-table-items:last-child {
  width: 0px;
}
