//
// apexcharts.scss
//

.apex-charts {
  min-height: 10px !important;

  text {
    font-family: $font-family-base !important;
  }

  .apexcharts-canvas {
    margin: 0 auto;
  }
}

@keyframes opaque {
  0% {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes resizeanim {
  0%,
  to {
    opacity: 0;
  }
}

.apexcharts-canvas {
  position: relative;
  user-select: none;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 6px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }

  :is(
      .apexcharts-reset-zoom-icon,
      .apexcharts-selection-icon,
      .apexcharts-zoom-icon
    ) {
    &.apexcharts-selected {
      svg {
        fill: var(--#{$prefix}primary);
      }
    }
  }

  @media screen and (min-width: 768px) {
    &:hover {
      .apexcharts-toolbar {
        opacity: 1;
      }
    }
  }
}

.apexcharts-inner {
  position: relative;
}

.apexcharts-gridline {
  pointer-events: none;
  stroke: rgba(var(--#{$prefix}light-rgb), 1);
}

.apexcharts-text tspan {
  font-family: inherit;
}

.apexcharts-yaxis,
.apexcharts-xaxis {
  text {
    font-family: $font-family-base !important;
    fill: $gray-600;
  }
}

.apexcharts-title-text,
.apexcharts-subtitle-text {
  fill: $gray-500;
}

.legend-mouseover-inactive {
  transition: 0.15s ease all;
  opacity: 0.2;
}

.apexcharts-legend-text {
  color: $gray-600 !important;
  font-family: $font-family-base !important;
  font-size: 13px !important;
  padding-left: 15px;
  margin-left: -15px;
}

.apexcharts-series-collapsed {
  opacity: 0;
}

.apexcharts-xaxis-tick {
  stroke: var(--#{$prefix}border-color);
}

.apexcharts-marker {
  stroke: var(--#{$prefix}secondary-bg);
}

.apexcharts-tooltip {
  border-radius: 5px;
  box-shadow: $box-shadow !important;
  cursor: default;
  font-size: 14px;
  left: 62px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  top: 20px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
  z-index: 12;
  transition: 0.15s ease all;

  &.apexcharts-active {
    opacity: 1;
    transition: 0.15s ease all;
  }

  &.apexcharts-theme-light {
    border: 1px solid var(--#{$prefix}border-color) !important;
    background: rgba(var(--#{$prefix}secondary-bg-rgb), 0.96) !important;

    .apexcharts-tooltip-title {
      background: var(--#{$prefix}secondary-bg) !important;
      border-bottom: 1px solid var(--#{$prefix}border-color) !important;
    }
  }

  &.apexcharts-theme-dark {
    color: #fff;
    background: rgba(30, 30, 30, 0.8);

    .apexcharts-tooltip-title {
      background: rgba(0, 0, 0, 0.7);
      border-bottom: 1px solid #333;
    }
  }

  * {
    font-family: inherit;
  }

  .apexcharts-tooltip-title {
    padding: 6px;
    font-size: 15px;
    margin-bottom: 4px;
  }
}

.apexcharts-tooltip-text-goals-value,
.apexcharts-tooltip-text-y-value,
.apexcharts-tooltip-text-z-value {
  display: inline-block;
  margin-left: 5px;
  font-weight: 600;
}

.apexcharts-tooltip-text-goals-label,
.apexcharts-tooltip-text-goals-value,
.apexcharts-tooltip-text-y-label,
.apexcharts-tooltip-text-y-value,
.apexcharts-tooltip-text-z-value,
.apexcharts-tooltip-title {
  &:empty {
    display: none;
  }
}

.apexcharts-tooltip-text-goals-label,
.apexcharts-tooltip-text-goals-value {
  padding: 6px 0 5px;
}

.apexcharts-tooltip-goals-group,
.apexcharts-tooltip-text-goals-label,
.apexcharts-tooltip-text-goals-value {
  display: flex;
}

.apexcharts-tooltip-text-goals-label,
.apexcharts-tooltip-text-goals-value {
  &:not(:empty) {
    margin-top: -6px;
  }
}

.apexcharts-tooltip-marker {
  width: 12px;
  height: 12px;
  position: relative;
  top: 0;
  margin-right: 10px;
  border-radius: 50%;
}

.apexcharts-tooltip-series-group {
  padding: 0 10px;
  display: none;
  text-align: left;
  justify-content: left;
  align-items: center;

  &.apexcharts-active {
    .apexcharts-tooltip-marker {
      opacity: 1;
    }
  }

  &.apexcharts-active,
  &:last-child {
    padding-bottom: 4px;
  }
}

.apexcharts-tooltip-series-group-hidden {
  opacity: 0;
  height: 0;
  line-height: 0;
  padding: 0 !important;
}

.apexcharts-tooltip-y-group {
  padding: 6px 0 5px;
}

.apexcharts-custom-tooltip,
.apexcharts-tooltip-box {
  padding: 4px 8px;
}

.apexcharts-tooltip-boxPlot {
  display: flex;
  flex-direction: column-reverse;
}

.apexcharts-tooltip-box {
  > div {
    margin: 4px 0;
  }

  span.value {
    font-weight: 700;
  }
}

.apexcharts-tooltip-rangebar {
  padding: 5px 8px;

  .category {
    font-weight: 600;
    color: #777;
  }

  .series-name {
    font-weight: 700;
    display: block;
    margin-bottom: 5px;
  }
}

.apexcharts-xaxistooltip,
.apexcharts-yaxistooltip {
  opacity: 0;
  pointer-events: none;
  color: #373d3f;
  font-size: 13px;
  text-align: center;
  border-radius: 2px;
  position: absolute;
  z-index: 10;
  background: #eceff1;
  border: 1px solid #90a4ae;
}

.apexcharts-xaxistooltip {
  padding: 9px 10px;
  transition: 0.15s ease all;

  &.apexcharts-theme-dark {
    background: rgba(0, 0, 0, 0.7);
    border: 1px solid rgba(0, 0, 0, 0.5);
    color: #fff;
  }

  &::after,
  &::before {
    left: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &::after {
    border-color: transparent;
    border-width: 6px;
    margin-left: -6px;
  }

  &::before {
    border-color: transparent;
    border-width: 7px;
    margin-left: -7px;
  }

  &.apexcharts-active {
    opacity: 1;
    transition: 0.15s ease all;
  }
}

.apexcharts-xaxistooltip-bottom {
  &::before,
  &::after {
    bottom: 100%;
  }

  &::after {
    border-bottom-color: #eceff1;
  }

  &::before {
    border-bottom-color: #90a4ae;
  }

  &.apexcharts-theme-dark {
    &::before,
    &::after {
      border-bottom-color: rgba(0, 0, 0, 0.5);
    }
  }
}

.apexcharts-xaxistooltip-top {
  &::before,
  &::after {
    top: 100%;
  }

  &::after {
    border-top-color: #eceff1;
  }

  &::before {
    border-top-color: #90a4ae;
  }

  &.apexcharts-theme-dark {
    &::before,
    &::after {
      border-top-color: rgba(0, 0, 0, 0.5);
    }
  }
}

.apexcharts-yaxistooltip {
  padding: 4px 10px;

  .apexcharts-theme-dark {
    background: rgba(0, 0, 0, 0.7);
    border: 1px solid rgba(0, 0, 0, 0.5);
    color: #fff;
  }

  &::before,
  &::after {
    top: 50%;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &::after {
    border-color: transparent;
    border-width: 6px;
    margin-top: -6px;
  }

  &::before {
    border-color: transparent;
    border-width: 7px;
    margin-top: -7px;
  }

  &.apexcharts-active {
    opacity: 1;
  }
}

.apexcharts-yaxistooltip-left {
  &::before,
  &::after {
    left: 100%;
  }

  &::after {
    border-left-color: #eceff1;
  }

  &::before {
    border-left-color: #90a4ae;
  }

  &.apexcharts-theme-dark {
    &::before,
    &::after {
      border-left-color: rgba(0, 0, 0, 0.5);
    }
  }
}

.apexcharts-yaxistooltip-right {
  &::before,
  &::after {
    right: 100%;
  }

  &::after {
    border-right-color: #eceff1;
  }

  &::before {
    border-right-color: #90a4ae;
  }

  &.apexcharts-theme-dark {
    &::before,
    &::after {
      border-right-color: rgba(0, 0, 0, 0.5);
    }
  }
}

.apexcharts-yaxistooltip-hidden {
  display: none;
}

.apexcharts-xcrosshairs,
.apexcharts-ycrosshairs {
  pointer-events: none;
  opacity: 0;
  transition: 0.15s ease all;

  &.apexcharts-active {
    opacity: 1;
    transition: 0.15s ease all;
  }
}

.apexcharts-ycrosshairs-hidden {
  opacity: 0;
}

.apexcharts-selection-rect {
  cursor: move;
}

.svg_select_boundingRect,
.svg_select_points_rot {
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
}

.apexcharts-selection-rect + g {
  .svg_select_boundingRect,
  .svg_select_points_rot {
    opacity: 0;
    visibility: hidden;
  }

  .svg_select_points_l,
  .svg_select_points_r {
    cursor: ew-resize;
    opacity: 1;
    visibility: visible;
  }
}

.svg_select_points {
  fill: #efefef;
  stroke: #333;
  rx: 2;
}

.apexcharts-svg {
  &.apexcharts-zoomable {
    &.hovering-zoom {
      cursor: crosshair;
    }

    &.hovering-pan {
      cursor: move;
    }
  }
}

.apexcharts-menu-icon,
.apexcharts-pan-icon,
.apexcharts-reset-icon,
.apexcharts-selection-icon,
.apexcharts-toolbar-custom-icon,
.apexcharts-zoom-icon,
.apexcharts-zoomin-icon,
.apexcharts-zoomout-icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
  line-height: 24px;
  color: #6e8192;
  text-align: center;
}

:is(
    .apexcharts-menu-icon,
    .apexcharts-reset-icon,
    .apexcharts-zoom-icon,
    .apexcharts-zoomin-icon,
    .apexcharts-zoomout-icon
  ) {
  svg {
    fill: #6e8192;
  }
}

.apexcharts-selection-icon {
  svg {
    fill: #444;
    transform: scale(0.76);
  }
}

.apexcharts-theme-dark {
  :is(
      .apexcharts-menu-icon,
      .apexcharts-pan-icon,
      .apexcharts-reset-icon,
      .apexcharts-selection-icon,
      .apexcharts-toolbar-custom-icon,
      .apexcharts-zoom-icon,
      .apexcharts-zoomin-icon,
      .apexcharts-zoomout-icon
    ) {
    svg {
      fill: #f3f4f5;
    }
  }

  .apexcharts-menu {
    background: rgba(0, 0, 0, 0.7);
    color: #fff;
  }
}

.apexcharts-theme-light {
  .apexcharts-menu-icon,
  .apexcharts-reset-icon,
  .apexcharts-selection-icon:not(.apexcharts-selected),
  .apexcharts-zoom-icon:not(.apexcharts-selected),
  .apexcharts-zoomout-icon {
    &:hover {
      svg {
        fill: #333;
      }
    }
  }

  .apexcharts-menu-item {
    &:hover {
      background: #eee;
    }
  }
}

.apexcharts-menu-icon,
.apexcharts-selection-icon {
  position: relative;
}

.apexcharts-reset-icon {
  margin-left: 5px;
}

.apexcharts-menu-icon,
.apexcharts-reset-icon,
.apexcharts-zoom-icon {
  transform: scale(0.85);
}

.apexcharts-zoomin-icon,
.apexcharts-zoomout-icon {
  transform: scale(0.7);
}

.apexcharts-zoomout-icon {
  margin-right: 3px;
}

.apexcharts-pan-icon {
  transform: scale(0.62);
  position: relative;
  left: 1px;
  top: 0;

  svg {
    fill: #fff;
    stroke: #6e8192;
    stroke-width: 2;
  }

  &:not(.apexcharts-selected):hover {
    svg {
      stroke: #333;
    }
  }
}

.apexcharts-toolbar {
  position: absolute;
  z-index: 11;
  max-width: 176px;
  text-align: right;
  border-radius: 3px;
  padding: 0 6px 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.apexcharts-menu {
  background: #fff;
  position: absolute;
  top: 100%;
  border: 1px solid #ddd;
  border-radius: 3px;
  padding: 3px;
  right: 10px;
  opacity: 0;
  min-width: 110px;
  transition: 0.15s ease all;
  pointer-events: none;

  .apexcharts-menu-open {
    opacity: 1;
    pointer-events: all;
    transition: 0.15s ease all;
  }
}

.apexcharts-menu-item {
  padding: 6px 7px;
  font-size: 12px;
  cursor: pointer;
}

.apexcharts-canvas .apexcharts-element-hidden,
.apexcharts-datalabel.apexcharts-element-hidden,
.apexcharts-hide .apexcharts-series-points {
  opacity: 0;
}

.apexcharts-datalabel,
.apexcharts-datalabel-label,
.apexcharts-datalabel-value,
.apexcharts-datalabels,
.apexcharts-pie-label {
  cursor: default;
  pointer-events: none;
}

.apexcharts-pie-label-delay {
  opacity: 0;
  animation-name: opaque;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease;
}

.apexcharts-annotation-rect,
.apexcharts-area-series .apexcharts-area,
.apexcharts-area-series
  .apexcharts-series-markers
  .apexcharts-marker.no-pointer-events,
.apexcharts-gridline,
.apexcharts-line,
.apexcharts-line-series
  .apexcharts-series-markers
  .apexcharts-marker.no-pointer-events,
.apexcharts-point-annotation-label,
.apexcharts-radar-series path,
.apexcharts-radar-series polygon,
.apexcharts-toolbar svg,
.apexcharts-tooltip .apexcharts-marker,
.apexcharts-xaxis-annotation-label,
.apexcharts-yaxis-annotation-label,
.apexcharts-zoom-rect {
  pointer-events: none;
}

.apexcharts-marker {
  transition: 0.15s ease all;
}

.resize-triggers {
  animation: 1ms resizeanim;
  visibility: hidden;
  opacity: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.contract-trigger:before,
.resize-triggers,
.resize-triggers > div {
  content: ' ';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.resize-triggers {
  > div {
    height: 100%;
    width: 100%;
    background: #eee;
    overflow: auto;
  }

  &::before {
    overflow: hidden;
    width: 200%;
    height: 200%;
  }
}

#chart-year,
#chart-quarter {
  width: 96%;
  max-width: 48%;
  box-shadow: none;
  padding-left: 0;
  padding-top: 20px;
  background: var(--#{$prefix}secondary-bg);
  border: 1px solid var(--#{$prefix}border-color);
}

#chart-year {
  float: left;
  position: relative;
  transition: 1s ease transform;
  z-index: 3;

  &.chart-quarter-activated {
    transform: translateX(0);
    transition: 1s ease transform;
  }
}

#chart-quarter {
  float: left;
  position: relative;
  z-index: -2;
  transition: 1s ease transform;

  &.active {
    transition: 1.1s ease-in-out transform;
    transform: translateX(0);
    z-index: 1;
  }
}

@media screen and (min-width: 480px) {
  #chart-year {
    transform: translateX(50%);
  }

  #chart-quarter {
    transform: translateX(-50%);
  }
}

.apexcharts-treemap,
.apexcharts-heatmap-series {
  rect {
    stroke: var(--#{$prefix}secondary-bg);
  }
}

.apexcharts-pie-series,
.apexcharts-bar-series {
  path {
    stroke: var(--#{$prefix}secondary-bg);
  }
}

.apexcharts-radialbar {
  .apexcharts-datalabels-group text {
    fill: var(--#{$prefix}body-color);
  }
}

.apexcharts-radialbar-track {
  path {
    stroke: var(--#{$prefix}border-color);
  }
}

// radar chart

.apexcharts-radar-series {
  polygon,
  line {
    stroke: var(--#{$prefix}border-color);
  }
}

.apexcharts-pie {
  circle,
  line {
    stroke: var(--#{$prefix}border-color);
  }

  text {
    fill: var(--#{$prefix}white);
  }
}

.apexcharts-xaxistooltip {
  &.apexcharts-theme-light {
    box-shadow: $box-shadow;
    color: var(--#{$prefix}secondary-color);
    background: var(--#{$prefix}secondary-bg);
    border: 1px solid var(--#{$prefix}border-color);
    font-family: $font-family-base;

    &::before {
      border-bottom-color: var(--#{$prefix}border-color);
    }
  }
}

.effect-chart {
  :is(.apexcharts-pie-series, .apexcharts-bar-series) {
    path {
      clip-path: polygon(50% 2%, 100% 0, 100% 100%, 0 100%, 0 0);
    }
  }
}

.apexcharts-grid-borders {
  line {
    stroke: var(--#{$prefix}border-color);
  }
}
